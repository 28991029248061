@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400&display=swap");
@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}
footer{
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.popup{
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.404);
}
.innerPopup{
  pointer-events: none;
  width: 40vw;
  height: 30vh;
  margin: 35vh 30vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: black;
  color: white;
  z-index: 2002;
}
.noMargin{
  margin: 0pc
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1B191A;
  color: white;
}
.titleMainDiv{
  width: 100%;

}
.tempMain {
  background: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.three {
  z-index: 1;
}
.tempCenter {
  text-align: center;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hover {
  transition: all 0.6s;
  opacity: 1;
}
.hover:hover {
  opacity: 0.5;
}
.hoverB {
  transition: all 1s;
  background: transparent;
  color: white;
}
.hoverB:hover {
  background: white;
  color: #1B191A;
}

.mainDiv {
  width: 100%;
}
.aboutLeft{
  width: 47%;
  margin-right: 3%;

}
.aboutRight{
  width: 47%;
  margin-left: 3%;


}
.aboutRightIn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
.middleAnnouncement {
  font-size: 20px; 
  display: block;
  margin-left: auto;
  margin-right: auto
}
#eventsoon{
  font-size: 25px; 
  margin-top: 15%;
}
canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}
.mainMarginDiv {
  width: 76%;
  margin: 40px 12%;
  text-align: left;
}
.pageTitle {
  font-size: 50pt;
  font-weight: 400;
  margin: 0px;
  margin-top: -10px;
}

.regularTitle {
  font-size:20pt;
  font-weight: 300;
  margin: 0px;
}
.regularSubTitle {
  font-size:16pt;
  font-weight: 400;
  margin: 0px;
  margin-left: 10px; 
  margin-bottom: 10px; 
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.rowSocial{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.profileDiv {
  width: 21%;
  margin: 20px 2%;
}

.profileImgDiv {
  position: relative;
  width: 90%;
  padding: 10px 5%;
}
.profileImg {
  position: relative;
  width: 100%;
  border-radius: 100%;
}

.profileTextDiv {
  width: 90%;
  margin: 5px 5%;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.profileSubtitle {
  font-size: 15px;
  font-weight: 800;
  margin: 0px;
}
.profileSubSubtitle {
  font-size: 13px;
}
.linkedinLink {
  text-decoration: none;
}


.teamSocialsDiv{
  height: 15px;
  margin-top: 10px;
}
.teamLinkLogo {
  margin: 0px 8px;
  height: 15px;
  transition: all 0.6s;
}
.teamIcon{
  height: 100%;
}
.teamLinkLogo:hover{
  opacity: 0.6;
}
nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}
.menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.navLinkText {
  color: white;
  padding: 4px 10px;
  margin: 0px 0px;
  text-decoration: none;
  /* border: solid 1px white; */
  border-radius: 10px;
  cursor: pointer;
}
.navLinkTextSpan{
  transition: all 0.6s;
  border-radius: 4px;
  padding: 2px 3px;
  margin: 0px;
}
.navLinkTextSpan:hover{
  color: #1B191A;
  background: white;
  
}
.regLinkText {
  color: white;
  margin: 0px 0px;
  text-decoration: none;
  border-bottom: solid 1px white;
  cursor: pointer;
}
.regLinkTextSpan{
  transition: all 0.6s;
  border-radius: 4px;
  margin: 0px;
}
.regLinkTextSpan:hover{
  color: #9e9e9e;
  
}
.mainLogo {
  width: 100%;
}
.mainTitle {
  color: white;
  font-weight:800;
  font-size: 23px;
  margin: 7px 0px;
}
/* .maroonText {
  color: white;
} */
.maroonLine {
  border-color: maroon;
}
.logoNav {
  width: 30px;
  margin-bottom: -10px;
}
.blackText {
  color: #1B191A;
}
.backgroundSkyline {
  position: fixed;
  filter: blur(4px);
  width: 50%;
  /* margin-top: -1%; */
  z-index: 1;
}
a {
  color: #fff;
}
.youtubeDiv{
  width: 31.3%;
  margin-left: 1%;
  margin-right: 1%;
}
.socials a {
  text-decoration: none;
  color: #fff;
}
.socials a:hover {
  text-decoration: underline;
}
.wrap {
  flex-wrap: wrap;
}
.navLinkImg{
  width: 46%;
  margin: 2% 2%;
}
.aboutTileImg{
  width: 100%;
}
.eventsDiv {
}
.eventDiv {
  position: relative;
}
.eventDateDiv {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventInfoDiv {
  width: 70%;
}
.eventInfoInDiv{
  width: 78%;
  margin: 20px 5%;
  background: #1B191A;
  border-radius: 10px;
  padding: 27px 6%;
  border: solid 1px white;
}
.eventInfoInInDiv{
  padding: 0px;
}
.eventDateText{
  background: #1B191A;
  padding: 8px;
  z-index: 2;
}
.verticalLine {
  border-left: 1px solid white;
  height: 100%;
  position: absolute;
  left: 15%;
  margin-left: -0.5px;
  top: 0;
}
.eventTitle{
  font-size: 25px;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 4px;
}
.eventText{
  margin: 0px;
}
.textPaddingLeft{
  padding-left: 6px;
}

.icon{
  width: 18px;
}
.icon2{
  width: 18px;
  border-radius: 15px;
}
.iconDiv{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.registerButton{
  padding: 10px 15px;
  background: white;
  border: none;
  border-radius: 6px;
  transition: all 0.6s;
  font-size: 15px;
  cursor: pointer;

}

.applyImgDiv{
  width: 60%;
  margin: 20px 20%;
}
.applyImg{
  width: 70%;
  margin-right: 30%;
}
.line{
  margin: 20px 0px;
}
.spaceBetween{
  justify-content: space-between;
}
.registerButton:hover{
 opacity: 0.7;
}
.centerRow{
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialLink{
 margin: 5px 4px;
 transition: all 0.6s;
}
.socialLink:hover{
  opacity: 0.6;
}
.eventImg{
  width: 100%;
  transition: all 0.6s;
}
.eventImg:hover{
  opacity: 0.4;
}
.rowwrap{
  display: flex;
  flex-direction: row;
}
.eventImgDiv{
  width: 31.3%;
  margin-left: 1%;
  margin-right: 1%;
}
.eventLink{
  border-bottom: white 1px solid;
  color: white;

  text-decoration: none;
  /* border: solid 1px white; */
  cursor: pointer;
}
@media screen and (max-width: 960px) {
  .eventImgDiv{
    width: 48%
  }
  .row {
    flex-wrap: wrap;
    width: 100%;
  }
  .profileDiv {
    width: 48%;
    margin: 20px 1%;
  }
  .mainMarginDiv {
    text-align: center;
  }
  .eventDiv{
    display: flex;
    flex-wrap: wrap;
  }
  .eventDateDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.eventDateText{
  padding: 0px;
  margin-top: 10px;
  margin-bottom: -6px;
  
}
  .eventInfoDiv{
    width: 98%;
    margin: 10px 1%;
    padding: 0px;
  }
  .verticalLine{
    display: none;
    visibility: hidden;
  }
  .innerPopup{
    pointer-events: none;
    width: 96vw;
    height: 60vh;
    margin: 20vh 2vw;
  }
}

@media screen and (max-width: 600px) {

  .row {
    flex-direction: column;
  }
  .mainTitle{
    font-size: 19px;
  }
  .profileDiv, .aboutLeft, .aboutRight {
    width: 98%;
    margin: 20px 1%;
  }
  .pageTitle {
    font-size: 40px;
  }
  .navLinkText {
    font-size: 11px;
    padding: 4px 5px;
  }
  .applyImg{
    width: 100%;
  }

  nav {
    margin: 0px;
    margin-top: 40px;
  }
  .logoNav {
    width: 25px;
  }
  .mainMarginDiv {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }
}
